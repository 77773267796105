import revive_payload_client_4sVQNw7RlN from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/astondom-frontend/all/astondom-front-20241015063807/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import initialPlugin_nVOZz4WWBl from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/sitis-nuxt-3/dist/runtime/plugins/initialPlugin.mjs";
import setCanonikal_eJ3HDgKmUc from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/sitis-nuxt-3/dist/runtime/server/common/setCanonikal.mjs";
import plugin_client_W86eSGFqyZ from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import toast_DCKv3PaVhZ from "/var/www/astondom-frontend/all/astondom-front-20241015063807/node_modules/sitis-nuxt-3/dist/runtime/plugins/toast.mjs";
import customSectionClasses_wk2E3ghrcB from "/var/www/astondom-frontend/all/astondom-front-20241015063807/plugins/customSectionClasses.ts";
import iFlat_client_EH5UyymorH from "/var/www/astondom-frontend/all/astondom-front-20241015063807/plugins/iFlat.client.ts";
import maska_UHaKf2z1iQ from "/var/www/astondom-frontend/all/astondom-front-20241015063807/plugins/maska.ts";
import sbjs_client_LNbFuxWJM7 from "/var/www/astondom-frontend/all/astondom-front-20241015063807/plugins/sbjs.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  initialPlugin_nVOZz4WWBl,
  setCanonikal_eJ3HDgKmUc,
  plugin_client_W86eSGFqyZ,
  chunk_reload_client_UciE0i6zes,
  toast_DCKv3PaVhZ,
  customSectionClasses_wk2E3ghrcB,
  iFlat_client_EH5UyymorH,
  maska_UHaKf2z1iQ,
  sbjs_client_LNbFuxWJM7
]