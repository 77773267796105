import { default as _91_46_46_46cms_93bcxbRMvQOuMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93LEaQvcBQkDMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/blog/[...slug].vue?macro=true";
import { default as indexEjIWm1yzITMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93Cws5eUhbheMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_removecpEXX4WB8PMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93L7OxZ7QGSTMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/product/[...slug].vue?macro=true";
import { default as indexqdxhxraoKpMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93zOMGJbEQ7rMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/realty/[...slug].vue?macro=true";
import { default as index2HIfQ8d7SgMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/sitemap/index.vue?macro=true";
import { default as indexf9X3qUHmpuMeta } from "/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93bcxbRMvQOuMeta?.name ?? "cms",
    path: _91_46_46_46cms_93bcxbRMvQOuMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93bcxbRMvQOuMeta || {},
    alias: _91_46_46_46cms_93bcxbRMvQOuMeta?.alias || [],
    redirect: _91_46_46_46cms_93bcxbRMvQOuMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93LEaQvcBQkDMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93LEaQvcBQkDMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93LEaQvcBQkDMeta || {},
    alias: _91_46_46_46slug_93LEaQvcBQkDMeta?.alias || [],
    redirect: _91_46_46_46slug_93LEaQvcBQkDMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexEjIWm1yzITMeta?.name ?? "blog",
    path: indexEjIWm1yzITMeta?.path ?? "/blog",
    meta: indexEjIWm1yzITMeta || {},
    alias: indexEjIWm1yzITMeta?.alias || [],
    redirect: indexEjIWm1yzITMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Cws5eUhbheMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93Cws5eUhbheMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93Cws5eUhbheMeta || {},
    alias: _91_46_46_46slug_93Cws5eUhbheMeta?.alias || [],
    redirect: _91_46_46_46slug_93Cws5eUhbheMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_removecpEXX4WB8PMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_removecpEXX4WB8PMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_removecpEXX4WB8PMeta || {},
    alias: temp_catalog_need_32_removecpEXX4WB8PMeta?.alias || [],
    redirect: temp_catalog_need_32_removecpEXX4WB8PMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93L7OxZ7QGSTMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93L7OxZ7QGSTMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93L7OxZ7QGSTMeta || {},
    alias: _91_46_46_46slug_93L7OxZ7QGSTMeta?.alias || [],
    redirect: _91_46_46_46slug_93L7OxZ7QGSTMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexqdxhxraoKpMeta?.name ?? "product",
    path: indexqdxhxraoKpMeta?.path ?? "/product",
    meta: indexqdxhxraoKpMeta || {},
    alias: indexqdxhxraoKpMeta?.alias || [],
    redirect: indexqdxhxraoKpMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93zOMGJbEQ7rMeta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93zOMGJbEQ7rMeta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93zOMGJbEQ7rMeta || {},
    alias: _91_46_46_46slug_93zOMGJbEQ7rMeta?.alias || [],
    redirect: _91_46_46_46slug_93zOMGJbEQ7rMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index2HIfQ8d7SgMeta?.name ?? "sitemap",
    path: index2HIfQ8d7SgMeta?.path ?? "/sitemap",
    meta: index2HIfQ8d7SgMeta || {},
    alias: index2HIfQ8d7SgMeta?.alias || [],
    redirect: index2HIfQ8d7SgMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexf9X3qUHmpuMeta?.name ?? "wish",
    path: indexf9X3qUHmpuMeta?.path ?? "/wish",
    meta: indexf9X3qUHmpuMeta || {},
    alias: indexf9X3qUHmpuMeta?.alias || [],
    redirect: indexf9X3qUHmpuMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241015063807/pages/wish/index.vue").then(m => m.default || m)
  }
]